/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    $('[data-nav-trigger]').on('click', function(){
        var $target = $('[data-nav-trigger]');
        var $targetLabel = $('[data-nav-label]');
        var label = $('[data-nav-label]').text();

        $target.toggleClass('active');

        if (label === 'MENU') {
            $targetLabel.text('CLOSE');
            $('[data-nav]').slideDown(300);
        } else {
            $targetLabel.text('MENU');
            $('[data-nav]').slideUp(300);
        }
    });
});


/*--------------------------------------------------------------------------*
 * ヘッダー固定
 *--------------------------------------------------------------------------*/
// $(function() {
//     var $win = $(window),
//         $main = $('#container'),
//         $header = $('header'),
//         headerHeight = $header.outerHeight(),
//         headerPos = $header.offset().top,
//         fixedClass = 'is-fixed';
    
//     if ($(window).width() <= 1022) {
//         $win.on('load scroll', function() {
//             var value = $(this).scrollTop();
//             if ( value > headerPos ) {
//                 $header.addClass(fixedClass);
//                 $main.css('margin-top', headerHeight);
//             } else {
//                 $header.removeClass(fixedClass);
//                 $main.css('margin-top', '0');
//             }
//         });
//     }
//   });